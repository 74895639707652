import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
// import { renderRoutes } from 'react-router-config';
import "./App.scss";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import Reducer from "./state/reducers";
import thunk from "redux-thunk";
import { ApolloProvider } from "react-apollo";
import Apollo from "./libs/Apollo";
import DragAndDrop from "./contexts/DragAndDrop";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/Pages/Login"));
const Register = React.lazy(() => import("./views/Pages/Register"));
const Page404 = React.lazy(() => import("./views/Pages/Page404"));
const Page500 = React.lazy(() => import("./views/Pages/Page500"));

const AppStore = createStore(Reducer, applyMiddleware(thunk));

class App extends Component {
  componentDidMount() {
    const preloader = document.getElementById("preloader");
    if (preloader) {
      setTimeout(() => {
        preloader.style.opacity = 0;
        setTimeout(() => {
          preloader.parentElement.removeChild(preloader);
        }, 500);
      }, 3000);
    }
  }
  render() {
    return (
      <ApolloProvider client={Apollo}>
        <Provider store={AppStore}>
          <HashRouter>
            <React.Suspense fallback={loading()}>
              <Switch>
                <Route
                  exact
                  path="/login"
                  name="Login Page"
                  render={(props) => <Login {...props} />}
                />
                <Route
                  exact
                  path="/register"
                  name="Register Page"
                  render={(props) => <Register {...props} />}
                />
                <Route
                  exact
                  path="/404"
                  name="Page 404"
                  render={(props) => <Page404 {...props} />}
                />
                <Route
                  exact
                  path="/500"
                  name="Page 500"
                  render={(props) => <Page500 {...props} />}
                />
                <Route
                  path="/"
                  name="Home"
                  render={(props) => <DefaultLayout {...props} />}
                />
              </Switch>
            </React.Suspense>
          </HashRouter>
        </Provider>
      </ApolloProvider>
    );
  }
}
const AppContext = DragAndDrop(App);
export default App;
