import {
  HttpLink,
  ApolloLink,
  ApolloClient,
  InMemoryCache,
} from "apollo-boost";
import { getUserToken } from "./authentication";
import { WebSocketLink } from "apollo-link-ws";
import { split } from "apollo-link";
import { getMainDefinition } from "apollo-utilities";

if (process.env.REACT_APP_SERVER_URL) {
  serverUrl = process.env.REACT_APP_SERVER_URL;
}
export const serverUrl = "https://hprp-cloud.com";
// export const serverUrl = "http://10.177.5.120:5000";
// export const serverUrl = "http://localhost:5000";
// export const serverUrl = "http://hprp-cloud.com";

// server hpp-regulasi dan hpp-putusan
export const hppServer = "http://localhost";
export const hppdocUrl = "https://doc.hprp-cloud.com"

const wsLink = new WebSocketLink({
  uri: serverUrl.replace("http", "ws") + "/graphql",
  options: {
    reconnect: true,
  },
});
const httpLink = new HttpLink({
  uri: serverUrl + "/graphql",
});

const Link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

// const httplink = new HttpLink({
//   uri: serverUrl + "/graphql"
// });

const apollolink = new ApolloLink((operation, forward) => {
  const token = getUserToken();
  operation.setContext({
    headers: {
      token: token ? token : "",
    },
  });

  return forward(operation);
});

const Apollo = new ApolloClient({
  link: apollolink.concat(Link),
  cache: new InMemoryCache(),
});

export default Apollo;
