function ViewControl(
  state = {
    showSidebar: false,
    boardMode: false
  },
  action
) {
  switch (action.type) {
    case "VIEW_SET_BOARD_MODE":
      {
        return {
          ...state,
          boardMode: action.value
        };
      }
      break;
    default:
      return state;
  }
}

export default ViewControl;
