/** DND 9.2.5 */
// import React from "react";
// import HTML5Backend from "react-dnd-html5-backend";
// import { DndProvider } from "react-dnd";

// const DragAndDrop = Component => {
//   return props => (
//     <DndProvider backend={HTML5Backend}>
//       <Component {...props} />
//     </DndProvider>
//   );
// };

// export default DragAndDrop;

import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";

export default DragDropContext(HTML5Backend);
