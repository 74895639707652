const Dummy = (
  state = {
    counter: 0
  },
  action
) => {
  switch (action.type) {
    case "UPDATE_COUNTER":
      return {
        ...state,
        counter: (state.counter += action.data)
      };
    default:
      return state;
  }
};

export default Dummy;
